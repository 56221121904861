<template>
  <div class="p-2 h-100">
    <div
      :class="[
        'w-100 h-100 p-3 position-relative',
        'd-flex flex-column',
        'justify-content-center align-items-center',
        'make-card border',
        selected && 'bg-brand-light-gray border filter-none selected'
      ]"
      @click="goTo"
    >
      <div
        :class="[
          'd-flex justify-content-center align-items-center',
          'make-option make-logo',
          !brandLogo && 'bg-brand-light-gray'
        ]"
      >
        <img
          :src="brandLogo"
          alt=""
          class="d-none w-100 user-select-none px-2"
          onerror="this.className='d-none'; this.src='https://vvs.autosyncstudio.com/static/icons/Autosync-logo-grey.png'; this.className='logo-opacity rounded bg-brand-light-gray';"
          onload="this.classList.remove('d-none')"
        >
        <div class="d-none preloader">
          <DotsFlashingLoader/>
        </div>
      </div>
      <div
        :class="[
          'text-center',
          'make-option make-name mb-1',
          selected && 'fw-bold',
          !brandName && 'bg-brand-light-gray'
        ]"
      >{{brandName}}</div>
      <div class="fs-count">
        <small class="fw-regular text-brand-muted">{{count ? `(${count})`:''}}</small>
      </div>
    </div>
  </div>
</template>

<script>
import DotsFlashingLoader from '@/components/shared/DotsFlashingLoader';

export default {
  name: 'BrandCard',
  components: {
    DotsFlashingLoader
  },
  props: {
    brandName: String,
    brandLogo: String,
    link: {
      type: String,
      default: null
    },
    selected: Boolean,
    count: Number
  },
  methods: {
    goTo: function () {
      if (this.link) {
        this.$router.push(this.link);
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

a {
  text-decoration: none;

  &:visited, &:link {
    color: #000;
  }
}

.filter-none img{
  filter: none !important;
}

.fs-count {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 12px;
}

img {
  filter: grayscale(100%);
  transition: .3s;
  max-width: 100%;
  max-width: 130px;
}

.make-card {
  &.selected {
    border-color: var(--client-color) !important;
  }

  &:hover {
    @extend .bg-brand-light-gray;
    cursor: pointer;

    & > .make-name {
      font-weight: 600;
      transition: .1s;
    }
  }

  &:hover img {
    filter: none;
  }
}

.make-card, .make-option {
  border-radius: 8px;
}

.make-logo {
  min-height: 90px;
  width: 61.8033988%;
}

.logo-opacity {
  height: 50%;
  width: 50%;
  opacity: .05;
}

.make-name {
  flex-grow: 1;
  min-height: 20px;
  margin-top: 10px;
  margin-left: 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

@media only screen and (max-width: 600px) {
  .make-name {
    font-size: 61.8033988%;
  }

  .make-logo {
    min-height: 70px;
    width: 96%;
  }

  img {
    max-width: 110px;
  }
}
</style>
