<template>
  <Container navbar small back-to="/home" message="select your brand">
    <section
      :class="[
        'col-12',
        'text-brand-text',
        'position-relative',
        searching && 'pt-5 pt-sm-0'
      ]"
    >
      <SearchToolMini style="z-index: 1" @query="updateSearch" @searching="updateSearchStatus"/>

      <div class="d-none d-sm-block container-fluid p-0 m-0">
        <div v-if="loaded" class="row justify-content-start aling-content-center m-0 m-md-3">
          <div
            v-for="(wheel, key) in wheels"
            :key="key"
            class="col-6 col-md-4 col-lg-3 col-xl-2 p-0 p-sm-2"
          >
            <BrandCard
              :brand-name="wheel.Brand"
              :brand-logo="`${logoUrl}${wheel.Logo}`"
              :link="`/builder?wheels_view&wheel_brand=${encodeURIComponent(wheel.Brand)}`"
            />
          </div>
        </div>
        
        <div v-else class="row justify-content-start aling-content-center m-0 m-md-3">
          <div
            v-for="(item, key) in wheels"
            :key="key"
            class="col-6 col-md-4 col-lg-3 col-xl-2"
          >
            <BrandCard
              :brand-name="null"
              :brand-logo="null"
            />
          </div>
        </div>
      </div>

      <div v-if="loaded" class="d-sm-none container-fluid p-0 m-0 py-2">
        <div class="row justify-content-start aling-content-center m-0 p-0">
          <div
            v-for="(wheel, key) in wheels"
            :key="key"
            class="col-6 p-1"
          >
            <router-link
              :class="[
                'w-100 h-100 px-3 py-2 bg-brand-body-secondary',
                'd-flex flex-xl-row',
                'justify-content-center align-items-center',
                'brand-card'
              ]"
              :to="`/builder?wheels_view&wheel_brand=${encodeURIComponent(wheel.Brand)}` || '#'"
            >
              <div class="text-start text-sm-center fw-regular brand-option brand-name">
                {{wheel.Brand || ''}}
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </Container>
</template>
<script>
import Container from "@/components/Container.vue";
import BrandCard from "@/components/wheels/BrandCard.vue";
import apiRequest from "@/utils/apiRequest";
import SearchToolMini from '@/components/shared/SearchToolMini.vue';

export default {
    name: "Wheels",
    components: {
      Container,
      BrandCard,
      SearchToolMini
    },
    data: function() {
      return {
        loaded: false,
        logoUrl: null,
        wheels: new Array(12).fill(),
        originalWheels: [],
        searchBrand: null,
        searching: false
      }
    },
    watch: {
      searching: function (newVal) {
        if (!newVal) {
          this.wheels = this.originalWheels;
        } else {
          this.searchBrand = null;
          this.originalWheels = this.wheels;
        }
      },
      searchBrand: function (newVal) {
        if (newVal) {
          this.wheels = this.originalWheels.filter(brand => brand.Brand.toUpperCase().trim().includes(newVal.toUpperCase().trim()));
        } else {
          this.wheels = this.originalWheels;
        }
      }
    },
    methods: {
      updateSearch: function (val) {
        this.searchBrand = val;
      },
      updateSearchStatus: function (val) {
        this.searching = val;
      }
    },
    beforeMount: async function() {      
      if (!('baseUrl' in this.$store.getters.wheelsBrands)) {
        let wheels = await apiRequest('/wheels/brands?i-logos&p-size=500');

        if (wheels) {
          this.wheels = wheels.Brands || null;
          this.logoUrl = wheels.BrandLogosUrlBase || null;
          this.$store.commit('wheelsBrands', {baseUrl: this.logoUrl, brands: this.wheels});
        }
      } else {
        const storedBrands = this.$store.getters.wheelsBrands;
        if (storedBrands) {
          this.wheels = storedBrands.brands || null;
          this.logoUrl = storedBrands.baseUrl || null;
        }
      }

      if (this.wheels.length === 1) {
        this.$router.push(`/builder?wheels_view&wheel_brand=${encodeURIComponent(this.wheels[0].Brand)}&direct_link`);
      }

      this.loaded = true;
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/scss/exportable.scss";

a {
  text-decoration: none;

  &:visited, &:link {
    color: #000;
  }
}

.brand-card {
  &:hover {
    @extend .bg-brand-light-gray;
    cursor: pointer;

    & > .brand-name {
      font-weight: 600;
      transition: .1s;
    }
  }
}

.brand-card, .brand-option {
  border-radius: 8px;
}

.brand-name {
  flex-grow: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  
  width: 100px;
  min-height: 15px;
  font-size: 12px;
  margin-left: 0;
  line-height: 1.5;
}
</style>